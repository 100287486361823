import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import '../styles/global.css';

import DirtLayout from '../components/DirtLayout';
import Seo from '../components/Seo';
import ContactForm from '../components/contact';
import DirtLinks from '../components/DirtLinks';

export default function DirtPage({ data }) {
   const dirts = data.allDirtservice.edges;
   return (
      <DirtLayout>
         <Seo title='Site Services' />
         <DirtHeader />
         <DirtLinks />
         <div className='bg-white overflow-hidden my-12'>
            <div className='relative max-w-7xl mx-auto py-8 md:py-16 px-4 sm:px-6 lg:px-8'>
               <div className='hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen' />
               <div className='mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none'>
                  <div>
                     <h2 className='text-base text-dirt font-semibold tracking-wide uppercase'>Site Services</h2>
                     <h3 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>What makes us different</h3>
                  </div>
               </div>
               <div className='mt-8 lg:grid lg:grid-cols-2 lg:gap-8'>
                  <div className='relative lg:row-start-1 lg:col-start-2'>
                     <svg
                        className='hidden lg:block absolute top-0 right-0 -mt-20 -mr-20'
                        width={404}
                        height={384}
                        fill='none'
                        viewBox='0 0 404 384'
                        aria-hidden='true'
                     >
                        <defs>
                           <pattern id='de316486-4a29-4312-bdfc-fbce2132a2c1' x={0} y={0} width={20} height={20} patternUnits='userSpaceOnUse'>
                              <rect x={0} y={0} width={4} height={4} className='text-gray-200' fill='currentColor' />
                           </pattern>
                        </defs>
                        <rect width={404} height={384} fill='url(#de316486-4a29-4312-bdfc-fbce2132a2c1)' />
                     </svg>
                     <div className='hidden md:flex justify-center items-center '>
                        <StaticImage
                           className='mx-auto max-w-prose rounded-lg shadow-lg'
                           src='../images/Hunter-Environmental-Dirt-Feature-1.jpg'
                           width={720}
                           alt='Hunter Environmental Excavator'
                        />
                     </div>
                  </div>
                  <div className='mt-8 lg:mt-0'>
                     <div className='mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1'>
                        <p>
                           When you need site work done accurately and quickly, you need Hunter Environmental! We specialize in solving problems that
                           other companies shy away from by using an innovative approach and techniques. From drainage, to demolition, to land
                           clearing, to site development we do it all. Call today to experience the Hunter Environmental difference.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <section className='my-8 md:my-16'>
            <div className='w-full h-96 flex justify-center items-center relative overflow-hidden'>
               <StaticImage
                  alt='Hunter Environmental panorama of trucks and heavy machinery'
                  src='../images/Hunter-Environmental-Dirt-Feature-2.jpg'
                  style={{
                     width: '100%',
                     height: '100%',
                     position: 'absolute',
                     top: '0px',
                  }}
                  objectFit='cover'
                  objectPosition='center'
               />
               <div aria-hidden='true' className='bg-gradient-to-b from-dirt-dark to-black opacity-80 absolute inset-0' />
               <div className='flex justify-center w-full items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white'>
                  <motion.blockquote
                     initial={{ y: 20, opacity: 0 }}
                     whileInView={{ y: 0, opacity: 1 }}
                     transition={{ duration: 1.5 }}
                     viewport={{ once: true }}
                     className='mt-12 md:mt-10 mx-8 md:mx-2 mb-12'
                  >
                     <div className='max-w-3xl  mx-auto text-center text-sm md:text-lg lg:text-xl leading-9 font-medium text-gray-50'>
                        <p>
                           &ldquo;Hunter Environmental helped me solve a major flooding problem at my home when no one else would. I interviewed
                           several contractors and even a civil engineer, and everybody else made up a reason why they couldn’t do the job. Nathaniel
                           took his time, thought outside the box, and worked with me to design and install a solution to keep the water in the ground
                           and out of my home. &rdquo;
                        </p>
                     </div>
                     <footer className='mt-8'>
                        <div className='md:flex md:items-center md:justify-center'>
                           <div className='mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center'>
                              <div className='text-base font-medium text-gray-50'>A. Jones</div>
                           </div>
                        </div>
                     </footer>
                  </motion.blockquote>
               </div>
            </div>
         </section>

         <div className='relative bg-white py-8 md:py-16'>
            <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
               <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.75 }}
                  viewport={{ once: true }}
               >
                  <h2 className='text-base font-semibold tracking-wider text-dirt uppercase'>Available Services</h2>
                  <p className='mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl'>The solutions to all your site work needs</p>
                  <p className='mt-5 max-w-prose mx-auto text-xl text-gray-500'>
                     We have the crew and equipment available to tackle any and all site work problems you may have.
                  </p>
               </motion.div>

               <div className='mt-12'>
                  <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2'>
                     {dirts.map((dirt, i) => (
                        <motion.div
                           initial={{ opacity: 0, y: 20 }}
                           whileInView={{ opacity: 1, y: 0 }}
                           transition={{ duration: 0.75, delay: i * 0.25 }}
                           viewport={{ once: true }}
                           key={dirt.id}
                           className='pt-6'
                        >
                           <div className='flow-root bg-gray-50 rounded-lg px-6 pb-8'>
                              <div className='-mt-6'>
                                 <div className='w-12 h-12 p-2 inline-flex items-center justify-center bg-dirt rounded-md shadow-lg '>
                                    <img src={dirt.node.icon} alt={dirt.node.alt} className='object-fill h-6 w-6' />
                                 </div>
                                 <h3 className='mt-8 text-lg font-medium text-gray-900 tracking-tight'>{dirt.node.name}</h3>
                                 <p style={{ fontFamily: 'Muli' }} className='mt-5 text-base text-gray-600'>
                                    {dirt.node.brief}
                                 </p>
                              </div>
                           </div>
                        </motion.div>
                     ))}
                  </div>
               </div>
            </div>
         </div>

         <ContactForm bgColor='bg-dirt' focusColor='focus:ring-dirt focus:border-dirt' />
      </DirtLayout>
   );
}

const DirtHeader = () => {
   return (
      <div className='grid'>
         <StaticImage
            style={{ gridArea: '1/1' }}
            layout='fullWidth'
            alt='Hunter Environmental Excavator digging up foundation'
            src='../images/Hunter-Environmental-Excavation-Header-Alt.jpg'
            formats={['auto', 'webp', 'avif']}
            className='h-128'
         />
         <div aria-hidden='true' style={{ gridArea: '1/1' }} className='bg-gradient-to-b from-black opacity-80 relative inset-0 z-10' />
         <div className='grid relative items-center w-full z-20' style={{ gridArea: '1/1' }}>
            <div className='w-full h-fit'>
               <div className='flex flex-col justify-center items-center md:items-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0'>
                  <div className='text-4xl lg:text-6xl text-center md:text-left md:pl-2 my-6 md:border-l-8 md:border-dirt font-bold text-white'>
                     <motion.h1
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className='mb-4 text-center md:text-left'
                     >
                        Hunter Environmental
                     </motion.h1>
                     <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                        Site Services
                     </motion.h1>
                  </div>
                  <motion.p
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     transition={{ delay: 0.75, duration: 0.75 }}
                     style={{ fontFamily: 'Muli' }}
                     className='text-gray-300 text-center mx-4 md:mx-0 md:text-left md:pl-4 mb-2  max-w-2xl'
                  >
                     ​​Site work professionals at your service
                  </motion.p>
                  <AnchorLink
                     to='/dirt#contact'
                     style={{ fontFamily: 'Muli' }}
                     className='w-max md:ml-4 shadow-lg mt-8 bg-dirt hover:bg-dirt-light  text-xs md:text-base rounded-md text-black px-12 py-2'
                  >
                     Contact Us
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
};

export const query = graphql`
   query {
      allDirtservice: allDirtservice {
         edges {
            node {
               icon
               id
               link
               name
               desc
               alt
               brief
            }
         }
      }
   }
`;
