import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

const ContactForm = (props) => {
   const data = useStaticQuery(graphql`
      query {
         site {
            siteMetadata {
               title
               phone
               email
               description
               siteUrl
            }
         }
      }
   `);
   const btn = classNames(
      'inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white hover:text-black hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ',
      props.bgColor
   );
   const inputField = classNames('block w-full shadow-sm py-3 px-4 placeholder-gray-500 border border-gray-300 rounded-md ', props.focusColor);

   return (
      <div id='contact' className='relative bg-white'>
         <div className='absolute inset-0'>
            <div className='absolute inset-y-0 left-0 w-1/2 bg-gray-50' />
         </div>
         <div className='relative max-w-7xl mx-auto lg:grid lg:grid-cols-5'>
            <div className='bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12'>
               <div className='max-w-lg mx-auto'>
                  <h2 className='text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl'>Get in touch</h2>
                  <p style={{ fontFamily: 'Muli' }} className='mt-3 text-md leading-6 text-gray-500'>
                     Have any questions or looking for a consultation. Reach out via phone or e-mail and we can get started on helping you out!
                  </p>
                  <dl className='mt-8 text-base text-gray-500'>
                     <div className='mt-6'>
                        <dt className='sr-only'>Phone number</dt>
                        <dd className='flex'>
                           <PhoneIcon className='flex-shrink-0 h-6 w-6 text-gray-400' aria-hidden='true' />
                           <span className='ml-3'>{data.site.siteMetadata.phone}</span>
                        </dd>
                     </div>
                     <div className='mt-3'>
                        <dt className='sr-only'>Email</dt>
                        <dd className='flex'>
                           <MailIcon className='flex-shrink-0 h-6 w-6 text-gray-400' aria-hidden='true' />
                           <a className='ml-3' href={`mailto:${data.site.siteMetadata.email}`}>
                              {data.site.siteMetadata.email}
                           </a>
                        </dd>
                     </div>
                  </dl>
               </div>
            </div>
            <div className='bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12'>
               <div className='max-w-lg mx-auto lg:max-w-none'>
                  <form
                     name='Hunter Environmental Contact Form'
                     method='POST'
                     data-netlify='true'
                     className='grid grid-cols-1 gap-y-6'
                     action='/success'
                     netlify-honeypot='bot-field'
                  >
                     <input type='hidden' name='form-name' value='Hunter Environmental Contact Form' />
                     <div>
                        <p className='hidden'>
                           <label>
                              Don’t fill this out if you’re human: <input name='bot-field' />
                           </label>
                        </p>
                        <label htmlFor='full-name' className='sr-only'>
                           Full name
                        </label>
                        <input
                           type='text'
                           name='full-name'
                           id='full-name'
                           autoComplete='name'
                           className={inputField}
                           placeholder='Full name'
                           required
                        />
                     </div>
                     <div>
                        <label htmlFor='email' className='sr-only'>
                           Email
                        </label>
                        <input id='email' name='email' type='email' autoComplete='email' className={inputField} placeholder='Email' required />
                     </div>
                     <div>
                        <label htmlFor='phone' className='sr-only'>
                           Phone
                        </label>
                        <input type='text' name='phone' id='phone' autoComplete='tel' className={inputField} placeholder='Phone' required />
                     </div>
                     <div>
                        <label htmlFor='message' className='sr-only'>
                           Message
                        </label>
                        <textarea id='message' name='message' rows={4} className={inputField} placeholder='Message' required />
                     </div>
                     <div>
                        <button type='submit' className={btn}>
                           Submit
                        </button>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ContactForm;
